var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"id":"register-view","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9","lg":"6"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('v-card',{staticClass:"pa-3 pa-md-10 mx-sm-auto",attrs:{"light":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSignUp)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required","debounce":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"firstName","color":"secondary","autofocus":"","autocomplete":"first-name","error-messages":errors,"placeholder":_vm.$t('user.first.name'),"prepend-icon":"mdi-account1"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"lastName","rules":"required","debounce":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"lastName","autocomplete":"family-name","error-messages":errors,"color":"secondary","placeholder":_vm.$t('user.last.name'),"prepend-icon":"mdi-face1"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email","debounce":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"new_user_email","error-messages":errors,"color":"secondary","autocomplete":"email","placeholder":_vm.$t('user.email'),"prepend-icon":"mdi-email1"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"","debounce":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-8",attrs:{"color":"secondary","type":"password","error-messages":errors,"autocomplete":"new-password","placeholder":_vm.$t('signin.password'),"prepend-icon":"mdi-lock-outline1"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-checkbox',{attrs:{"input-value":true,"color":"secondary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t("signup.i.agree.to.the")))]),_c('a',{staticClass:"secondary--text ml-6 ml-sm-1 d-inline-block",attrs:{"href":"therm_and_conditions"}},[_vm._v(" "+_vm._s(_vm.$t("signup.terms.and.conditions"))+" ")]),_vm._v(". ")]},proxy:true}],null,true)}),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","depressed":"","disabled":invalid,"loading":_vm.saving,"min-width":"140","rounded":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("signup.register"))+" ")]),(true)?_c('v-btn',{attrs:{"color":"secondary","rounded":"","text":"","large":""},on:{"click":_vm.signin}},[_vm._v(" "+_vm._s(_vm.$t("signin.button"))+" ")]):_vm._e()],1)])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }