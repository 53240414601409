<template>
  <v-container id="register-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12" md="9" lg="6">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-10 mx-sm-auto" light>
            <!-- <h1 class="text-center text-h2 font-weight-light">Utwórz konto</h1> -->

            <v-row>
              <!-- <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col v-for="(section, i) in sections" :key="i" cols="12">
                    <v-list-item three-line>
                      <v-list-item-icon class="mr-4 mt-5 mt-md-4">
                        <v-icon
                          :color="section.iconColor"
                          :large="$vuetify.breakpoint.mdAndUp"
                          v-text="section.icon"
                        />
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-h4 mb-4 mt-3"
                          v-text="section.title"
                        />

                        <v-list-item-subtitle
                          class="text--secondary"
                          v-text="section.text"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col> -->

              <v-col cols="12" md="12">
                <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
                  <v-form ref="form" autocomplete="on" v-model="valid" @submit.prevent="handleSubmit(onSignUp)">
                    <div class="text-center">
                      <!-- <v-btn
                    v-for="(social, i) in socials"
                    :key="i"
                    :color="social.iconColor"
                    class="my-2 mr-1"
                    dark
                    depressed
                    fab
                    small
                  >
                    <v-icon v-text="social.icon" />
                  </v-btn>

                  <div class="my-2" />

                  <div class="text-center text-h4">
                    or be classical
                  </div> -->
                      <validation-provider v-slot="{ errors }" name="firstName" rules="required" :debounce="200">
                        <v-text-field id="firstName" color="secondary" v-model="firstName" autofocus
                          autocomplete="first-name" :error-messages="errors" :placeholder="$t('user.first.name')"
                          prepend-icon="mdi-account1" />
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="lastName" rules="required" :debounce="200">
                        <v-text-field id="lastName" v-model="lastName" autocomplete="family-name"
                          :error-messages="errors" color="secondary" :placeholder="$t('user.last.name')"
                          prepend-icon="mdi-face1" />
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="email" rules="required|email" :debounce="200">
                        <v-text-field id="new_user_email" v-model="email" :error-messages="errors" color="secondary"
                          autocomplete="email" :placeholder="$t('user.email')" prepend-icon="mdi-email1" />
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="password" rules="" :debounce="200">
                        <v-text-field class="mb-8" v-model="password" color="secondary" type="password"
                          :error-messages="errors" autocomplete="new-password" :placeholder="$t('signin.password')"
                          prepend-icon="mdi-lock-outline1" />
                      </validation-provider>
                      <v-checkbox :input-value="true" color="secondary">
                        <template #label>
                          <span class="text-no-wrap">{{
                            $t("signup.i.agree.to.the")
                            }}</span>

                          <a class="secondary--text ml-6 ml-sm-1 d-inline-block" href="therm_and_conditions">
                            {{ $t("signup.terms.and.conditions") }} </a>.
                        </template>
                      </v-checkbox>
                      <v-btn color="secondary" class="mr-2"  depressed :disabled="invalid" :loading=saving
                        min-width="140" rounded type="submit">
                        {{ $t("signup.register") }}
                      </v-btn>
                      <v-btn color="secondary" v-if="true" rounded text large @click="signin">
                        {{ $t("signin.button") }}
                      </v-btn>
                      <!-- <v-btn
                          color="secondary"
                          depressed
                          :disabled="invalid"
                          :loading=saving
                          min-width="140"
                          rounded
                          @click="onResendEmail"
                        >
                          Resend email
                        </v-btn> -->
                    </div>
                  </v-form>
                </validation-observer>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"
import { signUp, resendEmail } from "@/services/auth.service"
import vue from "vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  name: "SignUpView",

  mounted() {
    extend("required", {
      ...required
    })
    extend("email", {
      ...email
    })
  },
  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
    valid: false,
    password: null,
    saving: false,
    sections: [
      {
        icon: "mdi-copyright",
        iconColor: "primary",
        title: "Security",
        text: "Informacje o security"
      },
      {
        icon: "mdi-shield-lock-outline",
        iconColor: "secondary",
        title: "MonPatrol",
        text: "Informacje o monpatrol"
      },
      {
        icon: "mdi-account-multiple",
        iconColor: "cyan",
        title: "Abonament",
        text: "Informacja o abonamencie"
      }
    ],
    socials: [
      {
        href: "#",
        icon: "mdi-twitter",
        iconColor: "#1DA1F2"
      },
      {
        href: "#",
        icon: "mdi-github",
        iconColor: "#24292E"
      },
      {
        href: "#",
        icon: "mdi-facebook",
        iconColor: "#3B5998"
      }
    ]
  }),
  beforeMount() {},
  methods: {
    onResendEmail() {
      console.log("onResendEmail :>> ")
      resendEmail(this.email)
        .then(response => {
          this.$dialog.notify.info(response.detail, {
            timeout: 10000
          })
        })
        .catch(error => {
          vue.$log.error(`Resend email errors ${error}`, error.response?.data)
          const data = error.response?.data
          if (data == null) {
            return
          }
          this.$dialog.notify.error(data, {
            timeout: 10000
          })
        })
    },
    signin() {
      this.$router.push({ name: "SignIn" })
    },
    onSignUp() {
      console.log("signUp :>> ")
      if (!this.$refs.form.validate()) {
        return false
      }
      this.saving = true
      signUp(this.firstName, this.lastName, this.email, this.password)
        .then(response => {
          this.$dialog.notify.info(response.detail, {
            timeout: 10000
          })
          // vue.$log.info("log in success", response)
          this.$router.push({ name: "Login" })
        })
        .catch(error => {
          this.saving = false
          vue.$log.error(`Register errors ${error}`, error.response?.data)
          const data = error.response?.data
          if (data == null) {
            return
          }
          // if (data.email) {
          // for (const message of data.email) {
          this.$dialog.notify.error(data, {
            timeout: 10000
          })
          // }
          // }
        })
      return true
    }
    // loginMock: function () {
    //
    //   this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
    //     this.$router.push('/')
    //   })
    // }
  }
}
</script>

<style lang="sass">
#register-view
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial

  .v-list-item__title
    font-size: 1.12rem

  .v-list-item__subtitle
    font-size: .875rem
    color: #3C4858
    line-height: 1.5em
    font-weight: 300
</style>
